import request from '@/utils/request'


// 电梯主体---------------------------------------------------
export function lifter_manage_query(data) {
    /**
     * 升降机管理
     * @param {number} querypage 要获取的页数
     * @param {number} pagesize 一页返回多少条
     * @returns {list} {
            "id": self.id,
            "lifter_id": self.lifter_id,
            "lifter_name": self.lifter_name,
            "lifter_model": self.lifter_model,
            "lifter_producer": self.lifter_producer,
            "Installation_address": self.Installation_address,
            "floor": self.floor
        }
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/query',
        method: 'post',
        data: data
    })
}

export function lifter_manage_get(data) {
    /**
     * 升降机获取单个数据
     * @param {string} lifter_id 要获取的电梯ID
     * @returns {dict} {
            "id": self.id,
            "lifter_id": self.lifter_id,
            "lifter_name": self.lifter_name,
            "lifter_model": self.lifter_model,
            "lifter_producer": self.lifter_producer,
            "Installation_address": self.Installation_address,
            "floor": self.floor
        }
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/get',
        method: 'post',
        data: data
    })
}

export function lifter_manage_create(data) {
    /**
     * 创建升降机
     * @param {string} lifter_id - 升降机的 ID。
     * @param {string} lifter_name - 升降机的名称。
     * @param {string} lifter_model - 升降机的型号。
     * @param {string} lifter_producer_id - 升降机生产商的 ID。
     * @param {string} Installation_address - 升降机的安装地址。
     * @param {number} floor - 层高。
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/create',
        method: 'post',
        data: data
    })
}

export function lifter_manage_delete(data) {
    /**
     * 删除升降机
     * @param {string} lifter_id - 要删除的升降机的ID。
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/delete',
        method: 'post',
        data: data
    })
}

// 生产商-----------------------------------------------------


export function lifter_manufacturer_manage(data) {
    // 不分页 返回全部厂商
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/query',
        method: 'post',
        data: data
    })
}


export function lifter_manufacturer_create(data) {
    /**
     * 创建厂商
     * @param {string} producer_name - 厂商名
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/create',
        method: 'post',
        data: data
    })
}


export function lifter_manufacturer_update(data) {
    /**
     * 更新厂商
     * @param {int} id - 要更新的数据的ID
     * @param {string} producer_name - 厂商名
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/update',
        method: 'post',
        data: data
    })
}


export function lifter_manufacturer_delete(data) {
    /**
     * 删除厂商
     * @param {int} id - 要删除的数据的ID
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/delete',
        method: 'post',
        data: data
    })
}


// 生产商产品-----------------------------------------------------
export function lifter_product_query(data) {
    /**
     * 升降机厂商产品查询
     * @param {producer_id} id - 要删除的数据的ID
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/product/query',
        method: 'post',
        data: data
    })
}
export function lifter_product_create(data) {
    /**
     * 升降机厂商产品创建
     * @param {producer_id} id - 升降机厂商ID
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/product/add',
        method: 'post',
        data: data
    })
}
export function lifter_product_get(data) {
    /**
     * 升降机厂商产品信息
     * @param {id} id - 升降机厂商产品的ID
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/product/get',
        method: 'post',
        data: data
    })
}
export function lifter_producer_errorcode_query(data) {
    /**
     * 升降机厂商产品错误码管理
     * @param {product_id} id - 要删除的数据的ID
     */
    return request({
        url: '/lifter_producer/product/errorcode/query',
        method: 'post',
        data: data
    })
}
// 电梯异常-----------------------------------------------------

export function lifter_manage_lifter_error_query(data) {
    /**
     * 获取升降机的所有异常
     * @param {string} lifter_id - 升降机ID
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/product/errorcode/query',
        method: 'post',
        data: data
    })
}


export function lifter_manage_lifter_error_create(data) {
    /**
     * 创建电梯异常
     * @param {string} lifter_id - 升降机ID
     * @param {string} error_code - 错误码
     * @param {string} error_info - 错误说明
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_producer/product/errorcode/add',
        method: 'post',
        data: data
    })
}


export function lifter_manage_lifter_error_update(data) {
    /**
     * 更新电梯异常
     * @param {int} id - 异常的系统ID
     * @param {string} error_code - 错误码
     * @param {string} error_info - 错误说明
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_error/update',
        method: 'post',
        data: data
    })
}


export function lifter_manage_lifter_error_delete(data) {
    /**
     * 删除异常
     * @param {int} id - 要删除的数据的ID
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/lifter_error/delete',
        method: 'post',
        data: data
    })
}


// 异常解决方法-----------------------------------------------------


export function lifter_manage_resolvent_query(data) {
    /**
     * 查询指定ID异常下的所有解决方法
     * @param {int} id - 升降机异常ID
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/resolvent/query',
        method: 'post',
        data: data
    })
}


export function lifter_manage_resolvent_create(data) {
    /**
     * 创建指定ID异常的解决方法
     * @param {int} lifter_error_code_id - 升降机异常ID
     * @param {str} possible_question - 可能的问题
     * @param {str} resolvent - 解决方法
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/resolvent/create',
        method: 'post',
        data: data
    })
}


export function lifter_manage_resolvent_delete(data) {
    /**
     * 删除解决方法
     * @param {int} id - 要删除的数据的ID
     */
    return request({
        url: '/platform_api/manage_api/lifter_manage/resolvent/delete',
        method: 'post',
        data: data
    })
}

